export const debounce = (fn, time) => {
  let timeout;

  return function (...args) { // <-- not an arrow function
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
};
