import { useState } from 'react'
import { debounce } from '../Utils/DebounceUtils'

export const useInput = (initialValue, useDebounce = false, debounceTime = 200) => {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        if (useDebounce) {
          debounce(setValue(event.target.value), debounceTime)
        } else {
          setValue(event.target.value)
        }
      },
    },
  }
}

export const usePriceInput = initialValue => {
  if (typeof initialValue !== 'string') {
    throw 'usePriceInput initial value must be a string!'
  }

  const [value, setValue] = useState(initialValue)

  // https://www.delftstack.com/howto/javascript/javascript-add-commas-to-number/
  const prettyPrice = priceStr => {
    const str = priceStr.toString().split('.')
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return str.join('.')
  }

  const isValidPriceNumber = value => {
    const numbers = '1234567890'
    const isNumber = value.split('').every(char => numbers.includes(char))

    const startsWithZero = value[0] === '0'

    const isLongerThanTen = value.length > 10

    return isNumber && !startsWithZero && !isLongerThanTen
  }

  const handleChange = event => {
    let val = event.target.value
    val = val
      .split('')
      .filter(item => item !== ',')
      .join('')

    if (isValidPriceNumber(val)) {
      setValue(val)
    }
  }

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value: prettyPrice(value),
      onChange: handleChange,
    },
  }
}

export const useCheckboxInput = initialValue => {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(false),
    bind: {
      checked: value,
      onChange: event => {
        setValue(event.target.checked)
      },
    },
  }
}
