import {
  CardActions,
  Divider
} from '@mui/material'

const RsCardFooterAction = ({children}) => {
  return (
    <>
      <Divider />
      <CardActions>
        { children }
      </CardActions>
    </>
  )
}

export default RsCardFooterAction
